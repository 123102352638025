import React, { useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import '/src/components/Base/SlickSlider/slick.scss'
import '/src/components/Base/SlickSlider/slick-theme.scss'
import './styles.scss'

const Button = loadable(() => import('/src/components/Basic/Buttons/Button'))
const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentSlider = loadable(() => import('react-slick'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))
const NumberSlider = loadable(() => import('/src/components/Base/NumberSlider'))

const InsuranceCalculator = ({ utm }) => {
    const [options] = useContext(OptionsContext)

    const contentSlider = useRef(null)
    const sliderItems = [
        {
            result: false,
            id: 'mortgage',
            title: 'Mortgage',
            description: 'How much is outstanding on your mortgage?',
            max: 1000000,
            default: 100000,
            add: true,
            input: useRef(null)
        },
        {
            result: false,
            id: 'debt',
            title: 'Debt',
            description: 'Total amount of debt (loans & credit cards)?',
            max: 30000,
            default: 1000,
            add: true,
            input: useRef(null)
        },
        {
            result: false,
            id: 'funeral',
            title: 'Funeral',
            description: 'How much would you like to include toward your funeral costs?',
            max: 10000,
            default: 2500,
            add: true,
            input: useRef(null)
        },
        {
            result: false,
            id: 'lumpsum',
            title: 'Lump Sum',
            description: 'How much would you like to leave as a lump sum?',
            max: 1000000,
            default: 100000,
            add: true,
            input: useRef(null)
        },
        {
            result: false,
            id: 'savings',
            title: 'Savings',
            description: 'What is your total combined existing life cover & savings?',
            max: 1000000,
            default: 5000,
            add: false,
            input: useRef(null)
        },
        {
            result: true,
            id: 'result',
            title: 'Result'
        }
    ]

    const contentSliderSettings = {
        dots: true,
        infinite: false,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        adaptiveHeight: true,
        appendDots: (dots) => (
            <div>
                <ol> {dots} </ol>
            </div>
        ),
        customPaging: (i) => {
            const currentItem = sliderItems[i]
            const title = currentItem.title
            return (
                <div className="slick-dots__item">
                    <span className="slick-dots__item-number">{i + 1}</span>
                    <span className="slick-dots__item-title">{title}</span>
                </div>
            )
        }
    }

    // Generate totals

    const userTotalCollection = []
    const total = useRef(null)

    return (
        sliderItems && (
            <div className="c-section__content c-insurance-calculator">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <ContentSlider ref={contentSlider} arrows={false} {...contentSliderSettings}>
                            {sliderItems.map((node, index) => {
                                const generateTotal = () => {
                                    const currentValue = Number(node.input.current.value)

                                    // If the number is supposed to be added, output a positive number, else output a negative number
                                    if (node.add) {
                                        userTotalCollection.push(currentValue)
                                    } else {
                                        userTotalCollection.push(-Math.abs(currentValue))
                                    }

                                    const userTotal = userTotalCollection.reduce(function (a, b) {
                                        return a + b
                                    }, 0)

                                    if (userTotal > 0) {
                                        total.current.innerHTML = `
                                                <h3 class="c-insurance-calculator__card-title">Total cover required: £${userTotal.toLocaleString()}</h3>
                                                <p class="c-insurance-calculator__card-description">Your loved ones could benefit from some life insurance</p>
                                                <div class="c-insurance-calculator__card-content">
                                                    <p>Based on the calculations, it looks like you need some life insurance to cover some of the costs you've stipulated and to protect your family.</p>
                                                    <p>Fortunately, that's what we're good at! Now you know how much cover you need we can provide you with quotes for an adequate level of protection.</p>
                                                </div>
                                            `
                                    } else {
                                        total.current.innerHTML = `
                                                <h3 class="c-insurance-calculator__card-title">No cover required</h3>
                                                <p class="c-insurance-calculator__card-description">Well, it looks like you might not need life insurance!</p>
                                                <div class="c-insurance-calculator__card-content">
                                                    <p>Lucky for some, eh? You have enough in savings and other protection policies that you don't require an additional policy to cover any of the essential costs you've stipulated.</p>
                                                    <p>However, if you still want to proceed with getting an additional policy, we can certainly help! Use our Quote Tool to find quotes for you, and we'll be in touch to get another policy arranged.</p>
                                                </div>
                                            `
                                    }
                                }

                                const next = () => {
                                    generateTotal()

                                    contentSlider.current.slickNext()
                                }

                                if (node.result) {
                                    const journeyLink = options?.journey.mainJourney.link
                                    const ctaData = {
                                        url: journeyLink,
                                        title: 'Get a quote'
                                    }

                                    return (
                                        <LargeCard key={index} className="slick-card">
                                            <div ref={total}></div>

                                            {journeyLink && (
                                                <CtaContainer align="centered" className="c-insurance-calculator__cta">
                                                    <ButtonLink data={ctaData} size="lg" icon="arrow" utm={utm} />
                                                </CtaContainer>
                                            )}
                                        </LargeCard>
                                    )
                                } else {
                                    return (
                                        <LargeCard key={index} className="slick-card">
                                            <h3 className="c-insurance-calculator__card-title">{node.title}</h3>
                                            <p className="c-insurance-calculator__card-description">
                                                {node.description}
                                            </p>

                                            <NumberSlider data={node} />

                                            <input
                                                readOnly
                                                className="slick-card__input"
                                                type="number"
                                                value={node.default}
                                                ref={node.input}
                                            />

                                            <CtaContainer align="centered" className="c-insurance-calculator__cta">
                                                <Button type="submit" size="lg" icon="chevron" onClick={next}>
                                                    Next
                                                </Button>
                                            </CtaContainer>
                                        </LargeCard>
                                    )
                                }
                            })}
                        </ContentSlider>
                    </div>
                </div>
            </div>
        )
    )
}

InsuranceCalculator.propTypes = {
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

export default InsuranceCalculator
